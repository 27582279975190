import React, { useMemo } from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import propTypes from 'prop-types';

import useCampaignInfo from '../data/useCampaignInfo';

import SEO from '../components/seo';

import useIsReferralCodeValid from '../hooks/useIsReferralCodeValid.hook';
import InvalidReferralCodeDialog from '@components/invalidReferralCodeDialog';
import TextHero from '@src/components/TextHero';
import ReferralSmsForm from '@src/components/ReferralSmsForm/ReferralSmsForm';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import useHasMounted from '@src/lib/useHasMounted';
import Layout from '@src/components/Layout/Layout';
import MinimalNavbar from '@src/components/MinimalNavbar/MinimalNavbar';
import MinimalFooter from '@src/components/MinimalFooter/MinimalFooter';
import Container from '@src/components/Container';
import TextArticle from '@src/components/TextArticle/TextArticle';
import CtaCards from '@src/components/CtaCards';
import {
  ContentfulTextSection,
  ContentfulCardsSectionComponent,
  ContentfulImageList,
} from '../../graphql-types';

import * as styles from '../pages/referral.module.scss';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';

type AffiliateTemplateProps = {
  data: any;
  pageContext: {
    affiliate: string;
  };
} & PageRendererProps;

const AffiliateTemplate = ({
  data,
  location: { search, pathname },
  pageContext: { affiliate },
}: AffiliateTemplateProps) => {
  const { pageContent, questions, pageHeader } = data.contentfulPage;

  const sgclid = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('sgclid');
  }, [search]);

  const { isValid, isLoading } = useIsReferralCodeValid(affiliate);
  const showCodeError = !isValid && !isLoading;

  const helpText = pageContent[0] as ContentfulTextSection;
  const signupSteps = pageContent[1] as ContentfulCardsSectionComponent;

  const isMecenat = affiliate === 'mecenat';
  const isAlumni = affiliate === 'alumni' || affiliate === 'senior';

  let campaignInfoID = 'Default Campaign';
  if (isMecenat) {
    campaignInfoID = 'Mecenat Campaign Info';
  }
  if (isAlumni) {
    campaignInfoID = 'Alumni Campaign Info';
  }

  if (affiliate === 'ahlden') {
    campaignInfoID = 'Ahlden Campaign Info';
  }

  if (affiliate === 'rokt') {
    campaignInfoID = 'Rokt Campaign Info';
  }

  const campaign = useCampaignInfo(campaignInfoID);
  const {
    pageTitle,
    pageSubtitle,
    pageSeoTitle,
    pageSeoDescription,
    pageParagraphText,
  } = campaign;

  const isMounted = useHasMounted();

  const imageList = pageContent[3] as ContentfulImageList;

  const img1 = useContentfulImage(imageList.images[0]);
  const img2 = useContentfulImage(imageList.images[1]);

  const footerContent = (
    <>
      <div style={{ paddingBottom: 60 }}>
        <ReferralSmsForm
          affiliateSclId={sgclid}
          referralCode={affiliate}
          headingText="Ladda ner appen och välj Rocker Plus eller Premium."
        />
      </div>
      <MinimalFooter />
    </>
  );

  return (
    <Layout
      customNavbar={<MinimalNavbar />}
      customFooter={footerContent}
      questions={questions}
      theme="soft"
    >
      {showCodeError && <InvalidReferralCodeDialog />}
      <SEO
        url={`https://rocker.com${pathname}`}
        //title={pageSeoTitle}
        description={pageSeoDescription}
        noIndex
      />
      <TextHero
        theme="soft"
        title={pageTitle || pageHeader}
        subtitle={pageSubtitle}
        boldSubtitle
        style={{ position: 'relative' }}
      >
        <ReferralSmsForm
          affiliateSclId={sgclid}
          referralCode={affiliate}
          headingText="Ladda ner appen och välj Rocker Plus eller Premium."
        />
        <div
          className={classNames({
            [styles.imgWrapper]: true,
            [styles.mounted]: isMounted,
          })}
        >
          <GatsbyImage
            className={styles.img1}
            alt={img1.title}
            image={img1.imageData}
          />
          <div className={styles.img2Wrapper}>
            <GatsbyImage
              className={styles.img2}
              alt={img2.title}
              image={img2.imageData}
            />
          </div>
        </div>
      </TextHero>
      <div style={{ backgroundColor: 'white' }}>
        <Container style={{ paddingTop: 80, paddingBottom: 1 }}>
          <TextArticle subheading={helpText.title} style={{ marginBottom: 80 }}>
            {pageParagraphText || helpText.content.content}
          </TextArticle>
          <CtaCards cards={signupSteps.card} />
        </Container>
      </div>
    </Layout>
  );
};

AffiliateTemplate.propTypes = {
  data: propTypes.object,
  location: propTypes.object,
  pageContext: propTypes.object,
};

export default AffiliateTemplate;

export const query = graphql`
  query AffiliateQuery {
    contentfulPage(pageUrl: { eq: "/referral-new" }) {
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      questions {
        answer {
          answer
        }
        question
      }
      pageContent {
        ... on ContentfulCardReferralContent {
          campaignTerms {
            campaignTerms
          }
          title
          mainText {
            mainText
          }
          referralSteps {
            icon {
              title
              file {
                url
              }
            }
            extraText
            title
            text
            longText {
              longText
            }
          }
        }
        ... on ContentfulCardsSectionComponent {
          id
          entryTitle
          card {
            title
            subtitle
            linkPath
            linkText
          }
        }
        ... on ContentfulFeature {
          icon {
            file {
              url
            }
          }
        }
        ... on ContentfulTextSection {
          title
          content {
            content
          }
        }
        ... on ContentfulFeatures {
          title
          feature {
            title
            childContentfulFeatureDescriptionTextNode {
              description
            }
            icon {
              file {
                url
              }
              title
            }
          }
        }
        ... on ContentfulImageList {
          id
          name
          images {
            gatsbyImageData(width: 324)
            title
          }
        }
      }
    }
  }
`;
