import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

/**
 *
 * @param {String} name The Contentful Name of the campaign.
 * @returns Campaign Info for the campaign panel
 */
const useCampaignInfo = name => {
  const { locale } = useIntl();
  const query = useStaticQuery(graphql`
    query CampaignInfoQuery {
      allContentfulCampaignInfo {
        edges {
          node {
            pageOverline
            pageSubtitle
            pageTitle
            pageParagraphText {
              pageParagraphText
            }
            pageSeoTitle
            pageSeoDescription
            name
            title
            node_locale
            terms {
              terms
            }
            text {
              text
            }
          }
        }
      }
    }
  `);

  const {
    allContentfulCampaignInfo: { edges: allCampaigns },
  } = query;
  const m = allCampaigns.find(
    c => (!name || c.node.name === name) && c.node.node_locale === locale,
  );

  if (!m) {
    return null;
  }

  const n = m.node;

  return {
    title: n.title,
    text: n.text ? n.text.text : null,
    terms: n.terms ? n.terms.terms : null,
    pageTitle: n.pageTitle,
    pageSubtitle: n.pageSubtitle,
    pageOverline: n.pageOverline,
    pageSeoTitle: n.pageSeoTitle,
    pageSeoDescription: n.pageSeoDescription,
    pageParagraphText: n.pageParagraphText
      ? n.pageParagraphText.pageParagraphText
      : null,
  };
};

export default useCampaignInfo;
